code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  /*height: 100%;*/
}

html {
  height: 100%;
  padding: 0;
  margin: 0;
  background: #000;
}

body {
  overflow-y: scroll;
}

* html {
  height: 100%;
}
