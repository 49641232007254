
@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}


.test {
  background-image: -webkit-linear-gradient(to left, #fff, #000);
  background-image: linear-gradient(to left, #fff, #000);

  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;

  -webkit-background-clip: text;
  background-clip: text;
}


.progress {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0;
  padding: 0;
}

.progress svg {
  transform: translateX(-100px) rotate(-90deg);
}

circle {
  stroke-dashoffset: 0;
  stroke-width: 5%;
  fill: none;
}

.bg {
  stroke: #b08fff;
  opacity: 0.2;
}

.indicator {
  stroke: #ff0000;
  opacity: 1;
}

.progress {
  stroke: #ff0000;
  opacity: 1;
}


