
$primary: rgb(120, 96, 187);
//$primary: rgb(68, 189, 234);
$secondary: rgb( 247, 244, 255);
$dark: rgb(10, 12, 14);
//$dark: rgb(11, 23, 36);
$dark-highlight: rgb(46,48,52);

$menu: rgb(27, 29, 32);

$info: rgb(34, 195, 203);


$btn-color: rgb(255,255,255);

$border-color: rgb(55,55,55);


$accordion-bg: $menu;
$accordion-color: #fff;
$accordion-button-active-color: $dark;


$card-bg: rgba(0, 0, 0, 0.15);
$card-border-color: $card-bg;

$dropdown-dark-bg: rgb(19, 22, 24);


$modal-content-bg: $dark;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-content-color: "#fff";

$form-range-track-height: 2px;
$form-range-thumb-width: 20px;
$form-range-thumb-height: 20px;

$font-weight-bolder: 900;




@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Space+Mono:wght@400;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

$font-family-sans-serif: 'Montserrat', sans-serif;
$headings-font-family: 'Montserrat', sans-serif;


$btn-border-radius: 0.5rem;
$border-radius: 0rem;
$border-radius-lg: 1rem;
$list-group-border-radius: 1rem;


$modal-content-border-radius: .5rem;

$nav-pills-link-active-bg: rgb(116, 84, 194, 0);
$nav-link-color: #bebebe;

$gray-50: #FBFBFC;
//$gradient: linear-gradient(180deg, rgba(#fff, .3), rgba(#fff, 0));
//$light: $gray-50;
$nav-link-hover-color: #fff;

.bg-dark-highlight {
  --bs-bg-opacity:1;
  background-color:rgba(46,48,52,var(--bs-bg-opacity))!important;
}

@import '~bootstrap/scss/bootstrap.scss';

.list-group-flush {
border-radius: 1rem;
}

.bg-menu {
  background-color: $menu;
}

.landing-jumbo-margin {
  margin-top: 50px;
  margin-bottom: 300px;
}

.test-shadow {
  -webkit-box-shadow: inset 0 0 30px 50px rgba(255,0,0,1);
  -moz-box-shadow: inset 0 0 30px 50px rgba(255,0,0,1);
  box-shadow: inset 0 0 30px 50px rgba(255,0,0,1);
}

.landing-jumbo {
  padding-top: 200px;
  padding-bottom: 200px;
}

@include media-breakpoint-up(sm) {
  .landing-jumbo {
    padding-top: 250px;
    padding-bottom: 250px;
  }
}

@include media-breakpoint-up(md) {
  .landing-jumbo {
    padding-top: 300px;
    padding-bottom: 300px;
  }
}

.p-landing-section-1 {
  padding-top: 75px;
  padding-bottom: 75px;
}

@include media-breakpoint-up(sm) {
  .p-landing-section-1 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}


@include media-breakpoint-up(md) {
  .p-landing-section-1 {
    padding-top: 250px;
    padding-bottom: 250px;
  }
}


.text-highlight-gradient {
  background: linear-gradient(90deg, #C5278A, #01C8EF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-jumbo-description {
  color: rgb(192, 192, 192) !important;
}


:root {
  --animation-duration: 0.25s;
}


.enter-fade {
  animation-duration: var(--animation-duration);
  animation-name: enter-fade-animation;
  animation-fill-mode: forwards;
}

.enter-fade-start {
  /*transform: translateY(300px);*/

  /*padding-top: 200px;*/
  opacity: 0;
}

@keyframes enter-fade-animation {
  from {
    opacity: 0;
    /*padding-top: 200px;*/
  }

  to {
    /*padding-top: 0;*/
    opacity: 100%;
  }
}

.solution-stack-row {
  margin-top: 3rem;
}

@include media-breakpoint-up(md) {
  .solution-stack-row {
    margin-top: 200px;
  }
}

@include media-breakpoint-up(md) {
  .contact-column-background {
    background: $secondary;
  }
}

.navbar {
  overflow-y: scroll !important;
}

@include media-breakpoint-up(lg) {
  .navbar {
    overflow: visible !important;
  }
}

